import { DecimalPipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { CellEditingStoppedEvent, ColDef, StatusPanelDef } from 'ag-grid-community';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Recipes, STiRResultPO } from 'src/app/models';
import { AgEventhandlerService } from 'src/app/services/ag-eventhandler.service';
import { UOMConversionService } from 'src/app/services/uom-conversion.service';
import { AppConstants, Metric, Volume } from '../../constants';
import { CustomMessageService, PDFService, SharedService } from 'src/app/services';
import { DropdownChangeEvent } from 'primeng/dropdown';


@Component({
  selector: 'app-recipe-details',
  templateUrl: './recipe-details.component.html',
  styleUrl: './recipe-details.component.scss'
})
export class RecipeDetailsComponent implements OnInit {

  recipeDetails: Recipes;
  crop: string;
  instance: DynamicDialogComponent | undefined;
  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {
    resizable: false,
    filter: false,
    sortable: false,
    flex: 1
  };
  ingColumnDefs: ColDef[] = [];
  dsUnit: any[] = [];
  dsIngredients: any[] = [];
  amountOfSeedUOM: string = Metric.Kg;
  totalVolumeUOM: string = Volume.mL;
  amountOfSeedUOMField: string = '';
  totalVolumeField: string = '';
  dsMixInstructionsText: any[] = [];
  gridUnitOptions = {
    columnDefs: this.columnDefs,
    defaultColDef: this.defaultColDef,
    suppressContextMenu: true,
    rowData: this.dsUnit,
  };
  gridUnitParams!: any;

  gridIngOptions = {
    suppressColumnVirtualisation: true,
    columnDefs: this.ingColumnDefs,
    rowData: this.dsIngredients,
  }
  gridIngParams!: any;
  statusBarIng!: {
    statusPanels: StatusPanelDef[];
  }

  amountOfSeedDictionary: { [key: string]: string } = { 'Lbs': 'Pounds', 'Kg': 'Kilograms' };
  volumeDictionary: { [key: string]: string } = { 'mL': 'Milliliters', 'L': 'Liters', 'gallon': 'gallon', 'fl oz': 'fl oz' };

  chooseUOMRB: string = Metric.Kg;
  countValue: number;

  selectedCrop: string = '';
  siRepoResponse: { [key: string]: number; } = {};

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const gridElement = document.querySelector('.ag-root');
    if (gridElement && !gridElement.contains(event.target as Node) && this.gridUnitParams) {
      this.gridUnitParams.api.stopEditing();
    }
  }

  get inputCountDisable(): { message: string, isValid: boolean } {
    if (this.selectedCrop === '') {
      return { message: 'Please select the crop to enable', isValid: false };
    } else {
      return { message: '', isValid: true };
    }
  }

  constructor(private ref: DynamicDialogRef, private dialogService: DialogService,
    private agSvc: AgEventhandlerService, private decimalPipe: DecimalPipe,
    private uomSvc: UOMConversionService, private pdfSvc: PDFService,
    private customMessageService: CustomMessageService, private sharedSvc: SharedService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    this.sharedSvc.fetchGetSeedsPerGram().subscribe({
      next: (res: STiRResultPO<string>) => {
        this.siRepoResponse = JSON.parse(res.sTiRResult);
      },
      error: () => {
        this.customMessageService.showMessage({ 'severity': 'error', summary: 'Error', detail: "Error in SI repo while getting crop details", data: AppConstants.Recipe_Details_Popup });
      }
    });
    this.statusBarIng = this.agSvc.getGridStatusBar();
    if (this.instance && this.instance.data) {
      this.crop = this.instance.data['crop'];
      this.recipeDetails = this.instance.data['recipeDetails'];
      if (this.recipeDetails.mixInstructionText !== null) {
        this.recipeDetails.mixInstructionText.split('\n').filter(x => x).forEach(item => {
          let isIngFound = this.recipeDetails.mixInstructions.find(x => x.instruction.toLowerCase() === item.toLowerCase());
          if (isIngFound) {
            this.dsMixInstructionsText.push({ mixingOrder: isIngFound.mixingOrder + ')', text: item, amount: '', type: 'Ingredient' });
          } else {
            this.dsMixInstructionsText.push({ mixingOrder: '', text: item, amount: '', type: 'Text' });
          }
        });
      }
    }
  }

  onCropValueChanged(ev: DropdownChangeEvent) {
    this.changeUOM();
  }

  createDataUnit() {
    this.columnDefs = [];
    this.dsUnit = [];
    this.amountOfSeedUOMField = `${this.amountOfSeedDictionary[this.amountOfSeedUOM] ? this.amountOfSeedDictionary[this.amountOfSeedUOM] : this.amountOfSeedUOM} of seed to be treated`;
    this.totalVolumeField = `Total ${this.totalVolumeUOM} to mix`;
    let newField: any = {};
    newField[this.amountOfSeedUOMField] = null;
    newField[this.totalVolumeField] = null;
    this.dsUnit.push(newField);
    const keys = Object.keys(this.dsUnit[0]);
    keys.forEach((key, i) => this.columnDefs.push({ field: key, cellDataType: 'number', headerName: key, suppressHeaderMenuButton: true, editable: i === 0 }));
    this.gridUnitParams.api.setGridOption("columnDefs", this.columnDefs);
    this.gridUnitParams.api.setGridOption("rowData", this.dsUnit);
  }

  createIngredientData() {
    this.ingColumnDefs = [];
    this.dsIngredients = [];
    this.recipeDetails.recipeIngredients.forEach((x, i) => {
      let newIngreident: any = {};
      newIngreident['Ingredient Name'] = (x.ingredient.labelAs === null || x.ingredient.labelAs === '') ? x.ingredient.name : x.ingredient.labelAs;
      newIngreident['Active Ingredient(s)'] = x.ingredient.activeIngredients;
      newIngreident['FACTS ID'] = x.ingredient.externalId;
      newIngreident['Target Rate mL/' + (this.amountOfSeedUOM === Metric.Lbs ? 'lb' : this.amountOfSeedUOM.toLowerCase())] = this.chooseUOMRB === Metric.Lbs ? this.convertTargetRateToMlPerLb(x.targetRate_ml_per_kg) : x.targetRate_ml_per_kg;
      newIngreident['Applicate Rate (' + this.totalVolumeUOM + ' per ' + (this.amountOfSeedUOM === Metric.Lbs ? 'pound ' : this.amountOfSeedUOM) + ' seed)'] = i === 0 ? (this.chooseUOMRB === Metric.Lbs ? this.convertTargetRateToMlPerLb(this.recipeDetails.csatApplicationRate_ml_per_kg) : this.recipeDetails.csatApplicationRate_ml_per_kg) : null;
      newIngreident['Total ' + this.totalVolumeUOM + ' you want to mix'] = null;
      newIngreident['Water (' + this.totalVolumeUOM + ')'] = null;
      newIngreident['Chemical (' + this.totalVolumeUOM + ')'] = null;
      this.dsIngredients.push(newIngreident);
    });
    if (this.dsIngredients.length > 0) {
      const keys = Object.keys(this.dsIngredients[0]);
      keys.forEach(key => {
        this.ingColumnDefs.push({ field: key, headerName: key, filter: true });
      });
      this.gridIngParams.api.setGridOption("columnDefs", this.ingColumnDefs);
      this.gridIngParams.api.setGridOption("rowData", this.dsIngredients);
    }
  }

  calculateIngredientChemicalAndWater(amountValue: any, totalVolumeValue: any) {
    if (this.dsIngredients.length > 0) {
      let sum = 0;
      this.dsIngredients.forEach((x, i) => {
        if (i === 0) {
          x['Total ' + this.totalVolumeUOM + ' you want to mix'] = this.sharedSvc.getOneDecimalAfterDot(totalVolumeValue);
        }
        x['Chemical (' + this.totalVolumeUOM + ')'] = this.sharedSvc.getOneDecimalAfterDot(amountValue * x['Target Rate mL/' + (this.amountOfSeedUOM === Metric.Lbs ? 'lb' : this.amountOfSeedUOM.toLowerCase())]);
        sum += x['Chemical (' + this.totalVolumeUOM + ')'];
        let miIng = this.dsMixInstructionsText.find(y => y.type === 'Ingredient' && y.text.toLowerCase() === x['Ingredient Name'].toLowerCase());
        if (miIng) {
          miIng.amount = x['Chemical (' + this.totalVolumeUOM + ')'] + ' ML';
        }
      });
      this.dsIngredients[0]['Water (' + this.totalVolumeUOM + ')'] = this.sharedSvc.getOneDecimalAfterDot(totalVolumeValue - sum);
      let miIng = this.dsMixInstructionsText.find(y => y.type === 'Ingredient' && y.text.toLowerCase() === 'Water'.toLowerCase());
      if (miIng) {
        miIng.amount = this.dsIngredients[0]['Water (' + this.totalVolumeUOM + ')'] + ' ML';
      }
      this.gridIngParams.api.setGridOption("rowData", this.dsIngredients);
    }
  }

  onUnitGridReady(params: any) {
    this.gridUnitParams = params;
    this.createDataUnit();
  }

  onIngGridReady(params: any) {
    this.gridIngParams = params;
    this.createIngredientData();
  }

  exportPDF() {
    this.pdfSvc.generateUOMCalculations(this.selectedCrop === '' ? this.crop : this.recipeDetails.cropMappings.find(x => x.cropCode === this.selectedCrop).cropDescription, this.recipeDetails, this.dsIngredients, this.dsUnit, this.dsMixInstructionsText);
  }

  onUOMSelect() {
    this.amountOfSeedUOM = this.chooseUOMRB;
    this.createDataUnit();
    if (this.countValue !== null && this.countValue !== undefined && this.selectedCrop !== '') {
      this.convertToPoundORKilograms();
    }
    this.gridUnitParams.api.setGridOption("rowData", this.dsUnit);
    this.createIngredientData();
    if (this.dsUnit[0][this.amountOfSeedUOMField] !== null && this.dsUnit[0][this.amountOfSeedUOMField] !== '' && this.dsUnit[0][this.totalVolumeField] !== null && this.dsUnit[0][this.totalVolumeField] !== '') {
      this.calculateIngredientChemicalAndWater(this.dsUnit[0][this.amountOfSeedUOMField], this.dsUnit[0][this.totalVolumeField]);
    } else {
      this.dsMixInstructionsText.filter(y => y.type === 'Ingredient').forEach(x => {
        x.amount = '';
      });
    }
  }

  convertToPoundORKilograms() {
    if (this.siRepoResponse[this.selectedCrop]) {
      let totalWeightinGrams = this.sharedSvc.getOneDecimalAfterDot(this.countValue / this.siRepoResponse[this.selectedCrop]);
      if (this.chooseUOMRB === Metric.Lbs) {
        this.dsUnit[0][this.amountOfSeedUOMField] = this.sharedSvc.getOneDecimalAfterDot(totalWeightinGrams * this.uomSvc.convertMass('Grams', 'Pounds'));
      } else {
        this.dsUnit[0][this.amountOfSeedUOMField] = this.sharedSvc.getOneDecimalAfterDot(totalWeightinGrams * this.uomSvc.convertMass('Grams', 'Kilograms'));
      }
      this.dsUnit[0][this.totalVolumeField] = this.recipeDetails.csatApplicationRate_ml_per_kg !== null ? this.sharedSvc.getOneDecimalAfterDot(this.dsUnit[0][this.amountOfSeedUOMField] * (this.chooseUOMRB === Metric.Lbs ? this.convertTargetRateToMlPerLb(this.recipeDetails.csatApplicationRate_ml_per_kg) : this.recipeDetails.csatApplicationRate_ml_per_kg)) : null;
    } else {
      this.customMessageService.showMessage({ 'severity': 'warn', summary: 'Warning', detail: "Selected crop do not found in SI Repo", data: AppConstants.Recipe_Details_Popup });
      this.dsUnit[0][this.amountOfSeedUOMField] = null;
      this.dsUnit[0][this.totalVolumeField] = null;
    }
  }

  changeUOM() {
    if (this.countValue !== null && this.countValue !== undefined) {
      this.amountOfSeedUOM = this.chooseUOMRB;
      this.createDataUnit();
      this.convertToPoundORKilograms();
      this.gridUnitParams.api.setGridOption("rowData", this.dsUnit);
      this.createIngredientData();
      if (this.dsUnit[0][this.amountOfSeedUOMField] !== null && this.dsUnit[0][this.amountOfSeedUOMField] !== '' && this.dsUnit[0][this.totalVolumeField] !== null && this.dsUnit[0][this.totalVolumeField] !== '') {
        this.calculateIngredientChemicalAndWater(this.dsUnit[0][this.amountOfSeedUOMField], this.dsUnit[0][this.totalVolumeField]);
      } else {
        this.dsMixInstructionsText.filter(y => y.type === 'Ingredient').forEach(x => {
          x.amount = '';
        });
      }
    }
  }

  close() {
    this.ref.close();
  }

  convertTargetRateToMlPerLb(targetrate: any) {
    let oneKGToPounds = 2.20462;
    return this.sharedSvc.getOneDecimalAfterDot(targetrate / oneKGToPounds);
  }

  checkIngredient(item: string) {
    let isIngFound = this.recipeDetails.mixInstructions.find(x => x.instruction.toLowerCase() === item.toLowerCase());

  }

  onPoundOrKilogramChanged(ev: CellEditingStoppedEvent) {
    if (ev.data[this.amountOfSeedUOMField] !== null && ev.data[this.amountOfSeedUOMField] !== '' && this.recipeDetails.csatApplicationRate_ml_per_kg !== null) {
      this.selectedCrop = '';
      this.countValue = null;
      ev.data[this.totalVolumeField] = ev.data[this.amountOfSeedUOMField] * (this.chooseUOMRB === Metric.Lbs ? this.convertTargetRateToMlPerLb(this.recipeDetails.csatApplicationRate_ml_per_kg) : this.recipeDetails.csatApplicationRate_ml_per_kg);
      ev.node.setData(ev.data);
      this.calculateIngredientChemicalAndWater(ev.data[this.amountOfSeedUOMField], ev.data[this.totalVolumeField]);
    }
  }

}
